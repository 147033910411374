import { Box, Flex } from "@chakra-ui/react";
import { useCart } from "@the-volte/svc-core-sdk";
import React, { useContext } from "react";
import Media from "react-media";

import { ssrMode } from "@app/constants";
import { MainMenuUserTools, NavItem } from "@components/atoms";
import MenuIcon from "@components/atoms/MainMenu/MenuIcon/MenuIcon";
import {
  MenuDropdown,
  OverlayContext,
  OverlayTheme,
  OverlayType,
} from "@components/legacy";
import { paths } from "@paths";
import { xLargeScreen } from "@styles/constants";

import { IProps } from "./types";

import cartImg from "images/cart-new.svg";
import userImg from "images/profile-new.svg";

export const MainMenuRight: React.FC<IProps> = ({
  menu,
  user,
  handleSignOut,
  login,
  resetActiveMenu,
}) => {
  const { items } = useCart();
  const overlayContext = useContext(OverlayContext);

  const menuItems = menu?.items || [];

  const isLoggedIn = !!user;
  const hasAddress = !!user?.addresses?.length;
  const isStaff = !!user?.isStaff;

  const cartItemsQuantity =
    (items &&
      items.reduce((prevVal, currVal) => prevVal + currVal.quantity, 0)) ||
    0;

  return (
    <Box
      onMouseEnter={resetActiveMenu}
      flex={{ base: "1 1 0px", lg: "none" }}
      width={{ lg: "8.313rem" }}
    >
      <Flex
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        marginInlineStart={0}
      >
        <Media
          query={{ minWidth: xLargeScreen }}
          defaultMatches={global.isDesktop} // SSR for Desktop
          render={() => (
            <>
              {isLoggedIn && (
                <MenuDropdown
                  label={user.firstName}
                  content={
                    <MainMenuUserTools
                      isLoggedIn={!!user}
                      handleSignOut={handleSignOut}
                      handleLogin={login}
                      isStaff={!!user?.isStaff}
                    />
                  }
                />
              )}
              {isLoggedIn ? (
                hasAddress || isStaff ? (
                  <NavItem
                    name={isStaff ? "Dashboard" : "Lend"}
                    url={paths.accountLending}
                    external
                  />
                ) : (
                  <NavItem name="Lend*" url={paths.accountAddressBook} />
                )
              ) : (
                <NavItem name="Log&nbsp;In" url="#" onClick={login} />
              )}
              <Box
                cursor="pointer"
                padding="0 0.5rem"
                onClick={() =>
                  overlayContext.show(OverlayType.cart, OverlayTheme.right)
                }
              >
                {`BAG\u00A0(${Number(cartItemsQuantity)})`}
              </Box>
            </>
          )}
        />
        <Media
          query={{ maxWidth: xLargeScreen - 1 }}
          defaultMatches={ssrMode && !global.isDesktop} // SSR for Mobile
          render={() => (
            <MenuIcon
              dataTest="desktopMenuToggleAccountOverlayIcon"
              onClick={() =>
                overlayContext.toggle(OverlayType.mobileAccount, undefined, {
                  data: menuItems,
                })
              }
              svgSrc={userImg.src}
            />
          )}
        />
        <Media
          query={{ maxWidth: xLargeScreen - 1 }}
          defaultMatches={ssrMode && !global.isDesktop} // SSR for Mobile
          render={() => (
            <MenuIcon
              dataTest="menuCartOverlayLink"
              onClick={() => {
                overlayContext.show(OverlayType.cart, OverlayTheme.right);
              }}
              svgSrc={cartImg.src}
              textOverlay={
                cartItemsQuantity > 0 ? String(cartItemsQuantity) : undefined
              }
            />
          )}
        />
      </Flex>
    </Box>
  );
};
